<template>
  <div>
    <FieldWrapper :field="field" :input-group="true">
      <input
        ref="password"
        v-model="$v.value.$model"
        :type="[showPassword ? 'text' : 'password']"
        class="form-control"
        :class="[validationClass]"
        :disabled="isDisabled"
        @input="onInput"
      />
      <div class="input-group-append" @click="showPassword = !showPassword">
        <span class="input-group-text">
          <i
            class="fal icon-lg cursor-pointer"
            :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
          />
        </span>
      </div>
    </FieldWrapper>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip :target="() => $refs.password" :errors="validationErrors" />
    <!------------ END: Error messages tooltip ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";

export default {
  components: { ErrorsTooltip, FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {
      showPassword: false
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
